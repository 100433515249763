import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';


export const getUser = async (email) => {
    try {
        const bookingDocRef = collection(db, "calendarUser");
        const q = query(bookingDocRef, where("email", "==", email));
        const bookingDocSnap = await getDocs(q);

        if (!bookingDocSnap.empty) {
            const userDoc = bookingDocSnap.docs[0];
            return { status: "success", data: { ...userDoc.data(), docID: userDoc.id } };
        } else {
            return { status: "not found" };
        }
    } catch (error) {
        throw error;
    }
};


export const updateUser = async (userId, updateData) => {
    try {
        const userDocRef = doc(db, "calendarUser", userId);

        await updateDoc(userDocRef, updateData);

        return { status: "success" };
    } catch (error) {
        throw error;
    }
};





export const emailTestingService = async (email) => {
    try {
        const response = await fetch('https://europe-west3-zoa-suite.cloudfunctions.net/getCalendarEvents?email=' + email, {
            method: "GET",
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return { message: 'Email not found' };
    }
};
