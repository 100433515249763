import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useScroll } from '../../context/scrollContext';
// import { atom, useRecoilState } from 'recoil';

// const userIdState = atom({
//     key: 'uid',
//     default: localStorage.getItem('uid') || null,
// });

const drawerWidth = 240;
const navItems = [
    {
        title: 'Precio',
        path: "Precio"
    },
    {
        title: 'Ayuda',
        path: "Ayuda"
    }
];

function MainLayout(props) {
    const { window, children, sx } = props;
    const navigate = useNavigate()
    const location = useLocation();
    const currentPathname = location.pathname;
    // const [userId, setUserId] = useRecoilState(userIdState);
    // const { scrollTo } = useScroll();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    // const userId = localStorage.getItem('uid');
    // console.log("userId: ", userId);


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Link style={{ textDecoration: "auto" }} to="/" >
                <Box sx={{ my: 1, display: "flex", columnGap: 2, justifyContent: "center", alignItems: "center" }}>
                    <img src="/images/Logo.svg" alt='Logo' />
                    <Typography sx={{ fontWeight: 700, fontSize: { sm: "32px", xs: "32px" } }} variant='h3'>ZOA</Typography>
                </Box>
            </Link>
            {/* <List>
                <Divider />
                {currentPathname === "/" && navItems.map((item) => (
                    <>
                        <ListItem key={item.title} disablePadding>
                            <ListItemButton onClick={() => scrollTo(item.path)} sx={{ textAlign: 'center' }}>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </>
                ))}
                {!userId ?
                    <>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate("/user-check")} sx={{ textAlign: 'center' }}>
                                <ListItemText primary="Acceder" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </>
                    :
                    <>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate("/subscription")} sx={{ textAlign: 'center' }}>
                                <ListItemText primary="Acceder" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </>
                }
            </List>
            {!userId &&
                <Button onClick={() => navigate("/register")} sx={{ textTransform: "math-auto", backgroundColor: "#2D5BFF" }} variant='contained'>
                    <Typography variant='h6'>Pruebalo 15 días gratis</Typography>
                </Button>
            } */}
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar sx={{ backgroundColor: "#fff", color: "#000", boxShadow: "none" }} component="nav">
                <Toolbar sx={{ minHeight: '48px !important' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
                    >
                        <Link style={{ textDecoration: "auto" }} to={location.pathname !== "/subscription" && "/"} >
                            <Box sx={{ display: "flex", columnGap: 2, justifyContent: "center", alignItems: "center" }}>
                                <img src="/images/Logo.svg" alt='Logo' />
                                <Typography sx={{ fontWeight: 700 }} variant='h3'>ZOA</Typography>
                            </Box>
                        </Link>
                    </Typography>
                    {/* {location.pathname === "/" &&
                        <Box sx={{
                            display: { xs: 'none', sm: 'flex' },
                            columnGap: "30px",
                        }}>
                            {navItems.map((item) => (
                                <Button onClick={() => scrollTo(item.path)} sx={{ textTransform: "math-auto", }} key={item.title}>
                                    <Typography sx={{ color: "#818487" }} variant='h6'>
                                        {item.title}
                                    </Typography>
                                </Button>
                            ))}
                            {!userId ?
                                <Button onClick={() => navigate("/user-check")} sx={{ textTransform: "math-auto" }}>
                                    <Typography sx={{ color: "#818487" }} variant='h6'>
                                        Acceder
                                    </Typography>
                                </Button>
                                :
                                <Button onClick={() => navigate("/subscription")} sx={{ textTransform: "math-auto" }}>
                                    <Typography sx={{ color: "#818487" }} variant='h6'>
                                        Acceder
                                    </Typography>
                                </Button>
                            }
                            {!userId ?
                                <Button onClick={() => navigate("/register")} sx={{ textTransform: "math-auto", backgroundColor: "#2D5BFF" }} variant='contained'>
                                    <Typography variant='h6'>Pruebalo 15 días gratis</Typography>
                                </Button>
                                :
                                <></>
                            }
                        </Box>
                    } */}
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Box component="main" sx={{ px: { sm: 4, xs: 2 }, width: "100%", maxWidth: "1280px", ...sx }}>
                    <Toolbar sx={{ minHeight: '48px !important' }} />
                    {children}
                </Box>
            </Box>
        </Box>
    );
}

MainLayout.propTypes = {
    window: PropTypes.func,
};

export default MainLayout;
