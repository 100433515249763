import logo from './logo.svg';
import './App.css';

function App() {

  const connectGoogleCalendar = () =>{
    window.open('https://europe-west3-zoa-suite.cloudfunctions.net/calendar-new/auth')
  }

  return (

    <>
    
    <h1 onClick={connectGoogleCalendar} style={{cursor:'pointer'}}>Connnect Your Google Calendar +</h1>
    
    </>
  );
}

export default App;
