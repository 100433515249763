import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    Navigate,
} from "react-router-dom";
import Home from '../pages/Home';

const AppRoutes = () => {


    // const LoginRoutes = () => {
    //     if (!userId) {
    //         return (
    //             <Outlet />
    //         )
    //     }
    //     else {
    //         return (
    //             <Navigate to="/" replace />
    //         )
    //     }
    // }

    // const PrivateRoutes = () => {
    //     if (userId) {
    //         return (
    //             <Outlet />
    //         )
    //     }
    //     else {
    //         return (
    //             <Navigate to="/" replace />
    //         )
    //     }

    // }


    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Home />} />
            </Routes>
        </BrowserRouter >
    );
};

export default AppRoutes;
