import { Button, Typography } from '@mui/material'
import React from 'react'

export default function BasicThemeButton({ title, onClick, style, textVariant, icon ,size }) {
    return (
        <Button
            onClick={onClick}
            size={size}
            sx={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
                borderRadius: "16px",
                background: "#04CE00",
                textTransform: "math-auto",
                boxShadow: "none",
                "&.MuiButtonBase-root:hover": {
                    boxShadow: "none"
                },
                ...style,
            }}
            variant='contained'>
            <Typography variant={textVariant}>{title}</Typography>
            {icon}
        </Button>
    )
}
