import React, { useEffect, useState } from 'react'
import MainLayout from '../../component/layout/mainLayout'
import { Box, Button, Grid, IconButton, MenuItem, Typography } from '@mui/material'
import GradientCard from '../../component/shared/gradientCard'
import PageLoader from '../../component/shared/loader'
import { ThemeSelect } from '../../component/shared/Input'
import AccountData from '../../component/subscriptions/accountData'

const panel = [
    { title: "Configuración chatbot", activeIcon: "/images/panel-icon/activeIcon1.svg", icon: "/images/panel-icon/icon1.svg" },
    { title: "Gestionar suscripción", activeIcon: "/images/panel-icon/activeIcon2.svg", icon: "/images/panel-icon/icon2.svg" },
    { title: "Datos de la empresa", activeIcon: "/images/panel-icon/activeIcon3.svg", icon: "/images/panel-icon/icon3.svg" },
    // { title: "Ir a los módulos de ZOA", activeIcon: "/images/panel-icon/activeIcon4.svg", icon: "/images/panel-icon/icon4.svg" },
]

export default function Home() {
    const [active, setActive] = useState(0)
    // const [userId, setUserId] = useRecoilState(userIdState);
    const [planData, setPlanData] = useState()
    const [loading, setLoading] = useState(false)
    // const navigation = useNavigate()


    // const getPlan = async () => {
    //     setLoading(true)
    //     const data = {
    //         uid: userId
    //     }
    //     const result = await getUserPlan(data)
    //     if (result.status === "success") {
    //         setLoading(false)
    //         setPlanData(result.data)
    //     } else {
    //         navigation("/register")
    //     }
    // }

    // useEffect(() => {
    //     getPlan()
    // }, [])




    const handleActive = (val) => {
        setActive(val)
    }

    // const handleLogout = () => {
    //     localStorage.removeItem('uid');
    //     setUserId(null);
    //     navigation("/login")
    // };


    return (
        <MainLayout sx={{ px: { sm: "70px", xs: 0 } }}>
            <Box sx={{ py: { sm: "20px", xs: 0 }, height: { sm: "92vh", xs: "auto" } }}>
                <GradientCard sx={{ height: "100%", overflow: "hidden", borderRadius: { xs: "0px", sm: "24px" } }}>
                    {loading ?
                        <PageLoader />
                        :
                        <Grid container height={"100%"}>
                            <Grid item xs={12} sm={3.8}>
                                <Box sx={{ width: "100%", height: "100%", background: "#0598B9", p: { sm: 4, xs: 2 }, display: "flex", flexDirection: { sm: "column", xs: "row" }, justifyContent: "space-between", alignItems: "flex-start" }}>
                                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", rowGap: { sm: 2, xs: 0.5 } }}>
                                        <Typography sx={{ fontSize: "26px", pl: { xs: 2, sm: 0 }, letterSpacing: "-1.6px", color: "white", fontWeight: 700 }} variant='h3'> ZOA Chatbot</Typography>
                                        {panel.map((value, index) =>
                                            <Button
                                                key={index}
                                                onClick={() => handleActive(index)}
                                                fullWidth
                                                variant='contained'
                                                sx={{
                                                    ml: 4,
                                                    borderRadius: "24px 0px 0px 24px",
                                                    display: { sm: "block", xs: "none" },
                                                    background: active === index ? "linear-gradient(180deg, #E4F8FD -284.38%, #E2F8F8 -175.25%, #DDF8EE 88.42%, #D6F8DB 547.92%)" : "transparent",
                                                    boxShadow: "none",
                                                    color: active === index ? "black" : "#fff",
                                                    "&.MuiButtonBase-root:hover": {
                                                        boxShadow: "none"
                                                    }
                                                }}
                                            >
                                                <Box sx={{ width: "100%", display: "flex", alignItems: "center", columnGap: 2 }}>
                                                    <Box component={"img"} sx={{ width: "50px", height: "50px" }} src={active === index ? value.activeIcon : value.icon} alt='' />
                                                    <Typography sx={{ textTransform: "none" }} variant='label'>{value.title}</Typography>
                                                </Box>
                                            </Button>
                                        )}
                                        <ThemeSelect
                                            onChange={(e) => handleActive(e.target.value)}
                                            value={active}
                                            sx={{
                                                display: { sm: "none", xs: "block" },
                                                '& .MuiInputBase-input': {
                                                    padding: 1,
                                                },
                                            }}
                                        >
                                            {panel.map((item, index) =>
                                                <MenuItem value={index} key={index}>{item.title}</MenuItem>
                                            )}
                                        </ThemeSelect>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, cursor: "pointer" }}>
                                        <IconButton sx={{ padding: "0px" }} variant='text'>
                                            <Box sx={{ width: { sm: "40px", xs: "20px" }, height: { sm: "40px", xs: "20px" } }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                                                    <path d="M30 24H4M4 24L11 18M4 24L11 30" stroke="#AEF0FF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M18.0039 14C18.0279 9.65 18.2219 7.294 19.7579 5.758C21.5159 4 24.3439 4 29.9999 4H31.9999C37.6579 4 40.4859 4 42.2439 5.758C43.9999 7.514 43.9999 10.344 43.9999 16V32C43.9999 37.656 43.9999 40.486 42.2439 42.242C40.7059 43.78 38.3499 43.972 33.9999 43.996M18.0039 34C18.0279 38.35 18.2219 40.706 19.7579 42.242C21.0399 43.526 22.8939 43.872 25.9999 43.966" stroke="#AEF0FF" stroke-width="3" stroke-linecap="round" />
                                                </svg>
                                            </Box>
                                        </IconButton>
                                        <Typography sx={{ color: "#fff", display: { sm: "block", xs: "none" } }} variant='label'>Cerrar sesión</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid sx={{ height: "100%", overflowY: "auto", position: "relative" }} item xs={12} sm={8.2}>
                                {active === 0 &&
                                    <AccountData />
                                }

                                {/* {active === 1 &&
                                    <ManageSubscription userId={userId} />
                                } */}

                                {/* {active === 2 &&
                                    <ManageUsers userId={userId} />
                                } */}
                                {/* 
                                {active === 3 &&
                                    <GoToZoa />
                                } */}

                            </Grid>
                        </Grid>
                    }
                </GradientCard>
            </Box>
        </MainLayout>
    )
}
